import React, { useState, useEffect } from "react";
import SectionLabel from "../../components/SectionLabel";
import AboutImageGrid from "../AboutUs/Sections/AboutImageGrid";
import { useNavigate } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import dynamicCulture from "../../assets/images/dynamicCulture.png";
import growthOpportunities from "../../assets/images/growthOpportunity.png";
import inovativeProjects from "../../assets/images/InovativeProjects.png";
import compititiveBenefits from "../../assets/images/compititiveBenefits.png";
import { useSelector } from "react-redux";
import hrLine from "../../assets/images/hrLine.png";

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

const Career = () => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const [jobData, setJobData] = useState([]);

  const getCareer = async () => {
    try {
      const res = await fetch("https://dev.spreeh.com/apps/careers");
      const data = await res.json();
      setJobData(data.careers.openings);
    } catch (error) {
      console.error("Error fetching careers data:", error);
    }
  };

  useEffect(() => {
    getCareer();
    Aos.init({ duration: 500 });
  }, []);

  const filteredJobs = jobData?.filter((job) =>
    job.title.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
  );

  const visibleJobs = isExpanded ? filteredJobs : filteredJobs?.slice(0, 6);

  const handleJobClick = (job) => {
    navigate("/careerJob", { state: { job } });
  };

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const { aboutUs: images } = useSelector((state) => state.home);

  return (
    <div className="w-full min-h-screen bg-white">
      <div className="mt-20 mb-1 flex justify-center align-center">
        <div className="flex flex-col w-full justify-center items-center h-full">
          <div
            className="block md:hidden flex flex-col items-center md:mx-0"
            data-aos="fade-up"
          >
            <h2
              className="text-base text-center md:text-xl font-normal mx-2 text-medium-pink"
              style={{
                fontFamily: "Tiro Devanagari Sanskrit, serif",
                fontWeight: 400,
                fontStyle: "italic",
              }}
            >
              {"Join"}
            </h2>
            <h2
              className="text-5xl text-center md:text-5xl font-normal mx-2 text-medium-pink"
              style={{
                fontFamily: "Tiro Devanagari Sanskrit, serif",
                fontWeight: 400,
                fontStyle: "italic",
              }}
            >
              {"Spreeh"}
            </h2>
          </div>
          <div
            className="hidden md:block md:flex md:flex-row md:items-center md:mx-0"
            data-aos="fade-up"
          >
            <h2
              className="text-center text-6xl font-normal mx-2 text-medium-pink"
              style={{
                fontFamily: "Tiro Devanagari Sanskrit, serif",
                fontWeight: 400,
                fontStyle: "italic",
              }}
            >
              {"Careers"}
            </h2>
          </div>
          <img
            alt="img"
            className="w-[130px] md:w-[190px] h-[20px] md:h-[28px] object-cover mt-10"
            src={hrLine}
            data-aos="fade-up"
          />
          <div
            className="max-w-[330px] md:max-w-3xl flex justify-center align-center text-center text-base font-normal mt-14"
            data-aos="fade-up"
          >
            At Spreeh, we’re always on the lookout for passionate and talented
            individuals to join our team. If you're a driven and hardworking
            individual enthusiastic about revolutionising the wedding planning
            industry, we’d love to hear from you.
          </div>
          <div className="mt-20 block md:hidden" data-aos="fade-right">
            <AboutImageGrid data={images && images?.length > 0 ? images : []} />
          </div>
          <div className="mt-20 md:mt-32" data-aos="fade-up">
            <div className="w-full">
              <SectionLabel
                text={"Why Join Spreeh"}
                styles={{
                  marginLeft: "2rem",
                  marginRight: "2rem",
                }}
              />
            </div>
          </div>
          <div className="w-full md:max-w-7xl grid grid-cols-2 md:grid-cols-4 gap-4 my-16 px-6 md:px-28">
            <div
              className="flex flex-col justify-center items-center mt-3 md:mt-0"
              data-aos="zoom-in"
              data-aos-easing="ease-in-sine"
            >
              <img
                src={dynamicCulture}
                className="w-[94px] h-[94px] md:w-[94px] h-[94px]"
                alt="icon1"
              />
              <div className="w-full text-center">
                <p className="font-semibold text-sm mt-2 font-['Manrope']">
                  Dynamic
                  <br />
                  Culture
                </p>
              </div>
            </div>
            <div
              className="flex flex-col justify-center items-center"
              data-aos="zoom-in"
              data-aos-easing="ease-in-sine"
            >
              <img
                src={growthOpportunities}
                className="w-[94px] h-[94px] md:w-[94px] h-[94px]"
                alt="icon2"
              />
              <div className="w-full text-center">
                <p className="font-semibold text-sm mt-2 font-['Manrope']">
                  Growth <br />
                  Opportunities
                </p>
              </div>
            </div>
            <div
              className="flex flex-col justify-center items-center mt-2 md:mt-0"
              data-aos="zoom-in"
              data-aos-easing="ease-in-sine"
            >
              <img
                src={inovativeProjects}
                className="w-[94px] h-[94px] md:w-[94px] h-[94px]"
                alt="icon3"
              />
              <div className="w-full text-center">
                <p className="font-semibold text-sm mt-2 font-['Manrope']">
                  Innovative <br />
                  Projects
                </p>
              </div>
            </div>
            <div
              className="flex flex-col justify-center items-center"
              data-aos="zoom-in"
              data-aos-easing="ease-in-sine"
            >
              <img
                src={compititiveBenefits}
                className="w-[94px] h-[94px]"
                alt="icon4"
              />
              <div className="w-full text-center">
                <p className=" font-semibold text-sm mt-2 font-['Manrope']">
                  Competitive <br />
                  Benefits
                </p>
              </div>
            </div>
          </div>
          <div className="w-full mx-4 md:max-w-7xl p-8" data-aos="fade-up">
            <div className="flex justify-between">
              <h2 className="text-2xl font-semibold mb-4">Current Openings</h2>
              <h2 className="text-base mb-4">{`${filteredJobs?.length} Jobs`}</h2>
            </div>
            <input
              type="text"
              placeholder="Search"
              className="w-full p-2 mb-4 border border-gray-300 rounded"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <ul className="space-y-2">
              {visibleJobs?.map((job, index) => (
                <li
                  key={index}
                  className="border-b p-2 mt-4 mb-4 cursor-pointer hover:bg-gray-100"
                  onClick={() => handleJobClick(job)}
                >
                  {job.title}
                </li>
              ))}
            </ul>
            {filteredJobs?.length > 6 && (
              <div className="flex justify-center">
                <button
                  className="mt-4 font-semibold text-medium-pink hover:underline"
                  onClick={handleToggle}
                >
                  {isExpanded ? "View less -" : "View more +"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Career;
