import React from 'react'
import TermsAndConditionsDetails from '../../components/TermsAndConditionsDetails'

export const TermsAndConditions = () => {
  return (
    <div className="w-full min-h-screen bg-white px-9 mt-20">
       <TermsAndConditionsDetails />
    </div>
  )
}
