import React from "react";

const SectionLabel = ({ text, styles }) => {
  return (
    <div className="flex flex-row w-full justify-center items-center h-full">
      <div className="flex items-center md:mx-0" style={styles}>
        <div className="w-12 h-0.5 bg-light-pink rounded-full"></div>
        <h2
          className="text-2xl text-center md:text-4xl font-normal mx-2 text-medium-pink"
          style={{
            fontFamily: "Tiro Devanagari Sanskrit, serif",
            fontWeight: 400,
            fontStyle: "italic",
          }}
        >
          {text}
        </h2>
        <div className="w-12 h-0.5 bg-light-pink rounded-full"></div>
      </div>
    </div>
  );
};

export default SectionLabel;
