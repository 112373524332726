import React from "react";

const PrimaryButton = ({ text, handleClick, styles }) => {
  return (
    <button
      className="px-4 py-2 bg-medium-pink rounded font-medium font-['Manrope'] transition duration-300 cursor-pointer hover:bg-rose-400"
      style={styles}
      onClick={handleClick}
    >
      {text}
    </button>
  );
};

export default PrimaryButton;
