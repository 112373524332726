import { useState, useEffect, useRef } from "react";
import CarouselItem from "./CarouselItem";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Carousel = ({ reviews, vendorMode = false }) => {
  const [currentIndex, setCurrentIndex] = useState();
  const [itemsToShow, setItemsToShow] = useState(3); // Default to 3 items for desktop
  const carouselRef = useRef(null);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true, // Enable arrows
    autoplay: true, // Enable autoplay
    autoplaySpeed: 5000, // Slide every 7 seconds
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setItemsToShow(1); // Show 1 item on mobile
      } else {
        setItemsToShow(3); // Show 3 items on desktop
      }
    };

    handleResize(); // Set initial value
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setCurrentIndex(Math.floor(reviews.length / 2));
    const interval = setInterval(() => {
      setCurrentIndex(
        (prevIndex) => (prevIndex + 1) % Math.ceil(reviews.length / itemsToShow)
      );
    }, 5000);
    return () => clearInterval(interval);
  }, [reviews.length, itemsToShow]);

  const handleIndicatorClick = (index) => {
    setCurrentIndex(index);
  };

  const handleTouchStart = (e) => {
    const touchStartX = e.touches[0].clientX;
    carouselRef.current.touchStartX = touchStartX;
  };

  const handleTouchMove = (e) => {
    if (!carouselRef.current.touchStartX) return;
    const touchMoveX = e.touches[0].clientX;
    const distance = touchMoveX - carouselRef.current.touchStartX;

    if (distance > 50) {
      // Swipe right
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? reviews.length - 1 : prevIndex - 1
      );
      carouselRef.current.touchStartX = null;
    } else if (distance < -50) {
      // Swipe left
      setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
      carouselRef.current.touchStartX = null;
    }
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? reviews.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex + 1) % Math.ceil(reviews.length / itemsToShow)
    );
  };

  return (
    <>
      <div
        className={`hidden md:block relative w-[1020px] ${
          vendorMode ? "h-[440px]" : "h-[540px]"
        }`}
      >
        <div className="flex justify-center items-center h-full">
          <Slider {...settings} className="w-full">
            {reviews.map((review, index) => (
              <CarouselItem key={index} {...review} vendorMode={vendorMode} />
            ))}
          </Slider>
        </div>
      </div>
      <div
        className={`block md:hidden relative w-full ${
          vendorMode ? "h-[440px]" : "h-[540px]"
        } overflow-hidden pl-8`}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        ref={carouselRef}
      >
        <div
          className="flex transition-transform duration-500 ease-in-out"
          style={{
            transform: `translateX(-${(currentIndex * 94.75) / itemsToShow}%)`,
          }}
        >
          {reviews.map((review, index) => (
            <div
              key={index}
              className={`flex-shrink-0 w-[calc(100%/${itemsToShow})]`}
            >
              <CarouselItem {...review} vendorMode={vendorMode} />
            </div>
          ))}
        </div>
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex space-x-2 mt-6">
          {Array.from({ length: Math.ceil(reviews.length / itemsToShow) }).map(
            (_, i) => (
              <div
                key={i}
                className={`w-2 h-2 rounded-full ${
                  i === currentIndex ? "bg-pink-500" : "bg-gray-300"
                }`}
                onClick={() => handleIndicatorClick(i)}
              />
            )
          )}
        </div>
      </div>
    </>
  );
};

export default Carousel;
