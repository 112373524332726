// import React, { useEffect, useState } from "react";

// const TermsAndConditionsDetails = () => {
//   const [terms, setTerms] = useState("");
//   const getTermsAndConditions = async () => {
//     const res = await fetch("https://dev.spreeh.com/apps/privacy");
//     const data = await res.json();
//     setTerms(data.terms);
//   };

//   useEffect(() => {
//     getTermsAndConditions();
//   }, []);
//   return (
//     <div>
//       <div className="text-base font-extrabold pb-4">Terms & Conditions</div>
//       <div dangerouslySetInnerHTML={{ __html: terms }} />
//     </div>
//   );
// };

// export default TermsAndConditionsDetails;

import React, { useEffect, useState } from "react";
import he from "he";

const TermsAndConditionsDetails = () => {
  const [terms, setTerms] = useState("");
  const getTermsAndConditions = async () => {
    const res = await fetch("https://dev.spreeh.com/apps/privacy");
    const data = await res.json();
    const decodedData = he.decode(data.terms);
    setTerms(decodedData);
  };

  useEffect(() => {
    getTermsAndConditions();
  }, []);
  return (
    <div className="md:px-48">
      <div className="text-base font-extrabold pb-4">Terms & Conditions</div>
      <div dangerouslySetInnerHTML={{ __html: terms }} />
    </div>
  );
};

export default TermsAndConditionsDetails;
