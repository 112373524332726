import React from "react";
import { useSpring, animated } from "react-spring";

const NumberAnimation = ({ num }) => {
  const { number } = useSpring({
    from: { number: 0 },
    number: num,
    delay: 200,
    config: { mass: 1, tension: 20, friction: 10 },
  });

  return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>;
};

const InfoQuoteNumbers = ({ stylesNum, stylesFont }) => {
  return (
    <div className="grid gap-x-10 gap-y-10 justify-center item-center text-center md:gap-20 md:flex md:flex-row md:justify-between pt-10 grid-cols-2">
      <div style={stylesNum}>
        <div className="flex justify-center align-center">
          <h2
            className="text-4xl md:text-6xl font-normal mb-1 flex justify-center"
            style={{
              fontFamily: "Tiro Devanagari Sanskrit, serif",
              fontWeight: 400,
              fontStyle: "italic",
            }}
          >
            <NumberAnimation num={10} />
            <span style={{ fontSize: "0.9em" }}> +</span>
          </h2>
        </div>
        <h2
          className="text-base md:text-lg font-normal mb-4 flex justify-center"
          style={{
            fontFamily: "Tiro Devanagari Sanskrit, serif",
            fontWeight: 400,
            ...stylesFont,
          }}
        >
          Countries
        </h2>
      </div>
      <div style={stylesNum}>
        <h2
          className="text-4xl md:text-6xl font-normal mb-1 flex justify-center"
          style={{
            fontFamily: "Tiro Devanagari Sanskrit, serif",
            fontWeight: 400,
            fontStyle: "italic",
          }}
        >
          <NumberAnimation num={50} />
          <span style={{ fontSize: "0.9em" }}> +</span>
        </h2>
        <h2
          className="text-base md:text-lg font-normal mb-4 flex justify-center"
          style={{
            fontFamily: "Tiro Devanagari Sanskrit, serif",
            fontWeight: 400,
            ...stylesFont,
          }}
        >
          Cities
        </h2>
      </div>
      <div style={stylesNum}>
        <h2
          className="text-4xl md:text-6xl font-normal mb-1 flex justify-center"
          style={{
            fontFamily: "Tiro Devanagari Sanskrit, serif",
            fontWeight: 400,
            fontStyle: "italic",
          }}
        >
          <NumberAnimation num={5000} />
          <span style={{ fontSize: "0.9em" }}> +</span>
        </h2>
        <h2
          className="text-base md:text-lg font-normal mb-4 flex justify-center"
          style={{
            fontFamily: "Tiro Devanagari Sanskrit, serif",
            fontWeight: 400,
            ...stylesFont,
          }}
        >
          Events
        </h2>
      </div>
      <div style={stylesNum}>
        <h2
          className="text-4xl md:text-6xl font-normal mb-1 flex justify-center"
          style={{
            fontFamily: "Tiro Devanagari Sanskrit, serif",
            fontWeight: 400,
            fontStyle: "italic",
          }}
        >
          <NumberAnimation num={10000} />
          <span style={{ fontSize: "0.9em" }}> +</span>
        </h2>
        <h2
          className="text-base md:text-lg font-normal mb-4 flex justify-center"
          style={{
            fontFamily: "Tiro Devanagari Sanskrit, serif",
            fontWeight: 400,
            ...stylesFont,
          }}
        >
          Happy Customers
        </h2>
      </div>
    </div>
  );
};

export default InfoQuoteNumbers;
