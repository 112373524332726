import React, { useEffect, useState } from "react";
import logo from "../assets/images/logo.png";
import fb from "../assets/images/facebook2.png";
import insta from "../assets/images/instagram.png";
import linkedin from "../assets/images/linkedin.png";
import tweet from "../assets/images/tweet.png";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { IoLogoWhatsapp } from "react-icons/io";

const Footer = () => {
  const navigate = useNavigate();
  // const [socialMedia, setSocialMedia] = useState([]);
  const { socialMediaData: socialMedia } = useSelector((state) => state.home);

  return (
    <footer className="py-8 mt-10">
      <div className="container mx-auto px-4 md:px-20">
        <div className="w-full flex flex-col items-center md:flex-row gap-8 md:gap-96">
          <div className="flex flex-col gap-2">
            <div className="pb-2">
              <Link to="/">
                <img className="w-28 h-11" alt="logo" src={logo} />
              </Link>
            </div>
            <div className="flex gap-2 pb-2">
              <a
                href={`https://facebook.com/${socialMedia?.fb}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={fb} alt="Facebook" className="w-6 h-6" />
              </a>
              <a
                href={`https://linkedin.com/company/${socialMedia?.linkedin}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedin} alt="linkedin" className="w-6 h-6" />
              </a>
              <a
                href={`https://instagram.com/${socialMedia?.insta}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={insta} alt="instagram" className="w-6 h-6" />
              </a>
              <a
                href={`https://x.com/${socialMedia?.twitter}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={tweet} alt="X" className="w-6 h-6" />
              </a>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-8 md:gap-20">
            <div className=" flex flex-col items-center md:items-start">
              <p className="font-['Manrope'] text-medium-pink font-extrabold text-xs pb-2 md:pb-4 md:text-sm">
                EVENTS
              </p>
              <Link
                to="/"
                className="font-['Manrope'] text-black font-medium text-xs pb-2 md:pb-3 md:text-sm"
              >
                Home
              </Link>
              <Link
                to="/services"
                className="font-['Manrope'] text-black font-medium text-xs pb-2 md:pb-3 md:text-sm"
              >
                Services
              </Link>
              <a
                href="/"
                className="font-['Manrope'] text-black font-medium text-xs pb-2 md:pb-3 md:text-sm"
                target="_blank"
                rel="noopener noreferrer"
              >
                Blogs
              </a>
              <Link
                to="/partner-with-us"
                className="font-['Manrope'] text-black font-medium text-xs pb-2 md:pb-3 md:text-sm"
              >
                Become a Partner
              </Link>
            </div>
            <div className=" flex flex-col items-center md:items-start">
              <p className="font-['Manrope'] text-medium-pink font-extrabold text-xs pb-2 md:pb-4 md:text-sm">
                COMPANY
              </p>
              <Link
                to="/about-us"
                className="font-['Manrope'] text-black font-medium text-xs pb-2 md:pb-3 md:text-sm"
              >
                About Us
              </Link>
              <p
                className="font-['Manrope'] text-black font-medium text-xs pb-2 md:pb-3 md:text-sm"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/privacy");
                }}
              >
                Privacy Policy
              </p>
              <p
                className="font-['Manrope'] text-black font-medium text-xs pb-2 md:pb-3 md:text-sm"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/terms");
                }}
              >
                Terms & Conditions
              </p>
              <Link
                to="/contact-us"
                className="font-['Manrope'] text-black font-medium text-xs pb-2 md:pb-3 md:text-sm"
              >
                Contact Us
              </Link>
              <Link
                to="/careers"
                className="font-['Manrope'] text-black font-medium text-xs pb-2 md:pb-3 md:text-sm"
              >
                Careers
              </Link>
            </div>
            <div className=" flex flex-col items-center md:items-start">
              <p className="hidden md:block font-['Manrope'] text-medium-pink font-extrabold text-xs pb-2 md:pb-4 md:text-sm">
                CONTACT DETAILS
              </p>
              <p className="block md:hidden font-['Manrope'] text-medium-pink font-extrabold text-xs pb-2 md:pb-3 md:text-sm">
                CONTACT INFO
              </p>
              <p className="font-['Manrope'] flex text-black font-medium text-xs pb-2 md:pb-3 md:text-sm">
                <IoLogoWhatsapp className="mt-[2px] md:mt-[4px] mr-1" /> +91
                9321941846 / +91 9967637322
              </p>
              <a
                href="mailto:contact@spreeh.com"
                className="font-['Manrope'] text-black font-medium text-xs pb-2 md:pb-3 md:text-sm"
                target="_blank"
                rel="noopener noreferrer"
              >
                contact@spreeh.com
              </a>
              <p className="font-['Manrope'] text-black font-medium text-xs pb-2 md:pb-3 md:text-sm">
                Mumbai, Maharashtra, India
              </p>
            </div>
          </div>
        </div>
      </div>
      <footer className="bg-gray-100 py-3 mt-24">
        <div className="text-center font-medium text-pink-500 text-xs">
          © 2024, Chekh Innovations Private Limited. All Rights Reserved
        </div>
      </footer>
    </footer>
  );
};

export default Footer;
