import { useState, useEffect } from "react";

const CarouselItem = ({
  imgUrl,
  imgPhUrl,
  location,
  reviews,
  testimonial,
  client,
  vendorMode,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={`relative w-[330px] ${
        vendorMode ? "h-[400px]" : "h-[500px]"
      } flex-shrink-0 mx-1`}
    >
      <img
        src={isMobile ? imgPhUrl : imgUrl}
        alt={client}
        className="w-[330px] h-full object-cover rounded-lg"
      />
      <div className="absolute inset-0 bg-gradient-to-t bg-opacity-20 from-black/50 from-1% to-transparent flex flex-col justify-end items-center text-white p-4 rounded-lg">
        <h3 className="text-2xl font-semibold text-[#EE978D]">{client}</h3>
        {!vendorMode && (
          <div className="flex mt-2">
            {[...Array(5)].map((_, i) => (
              <svg
                key={i}
                className={`w-4 h-4 ${
                  i < reviews ? "text-white" : "text-gray-500"
                }`}
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 15l-3.09 1.628L7.5 12.5 5 10l3.31-.11L10 7l1.69 2.89L15 10l-2.5 2.5.59 4.128L10 15z" />
              </svg>
            ))}
          </div>
        )}
        <p className="mt-4 mb-4 text-center font-normal text-xs">
          {testimonial}
        </p>
      </div>
    </div>
  );
};

export default CarouselItem;
