import React, {useState, useEffect, lazy, Suspense} from 'react';
import {useSelector} from 'react-redux';

// Lazy load the components
const BackToTopButton = lazy(() => import('../../components/BackToTopButton'));
const Packages = lazy(() => import('../Home/Sections/Packages'));
const ImageGrid = lazy(() => import('../Home/Sections/ImageGrid'));
const Conceairge = lazy(() => import('../Home/Sections/Conceairge'));
const Faq = lazy(() => import('../Home/Sections/Faq'));
const VendorTestimonial = lazy(() =>
  import('../Home/Sections/VendorTestimonial'),
);
const ClientTestimonials = lazy(() =>
  import('../Home/Sections/ClientTestimonials'),
);
const EventList = lazy(() => import('../../components/EventList'));

export const Services = () => {
  const {imgGrid} = useSelector(state => state.home);

  const {items: packages} = useSelector(state => state.package);
  const {item: testimonials} = useSelector(state => state.testimonial);

  return (
    <div className="w-full min-h-screen bg-white">
      <Suspense>
        <div className="w-full flex justify-center align-center">
          <EventList packages={packages} />
        </div>
        <ImageGrid
          images={imgGrid && imgGrid.length > 0 ? imgGrid.slice(2, 8) : []}
        />
        <ClientTestimonials data={testimonials?.customer} />
        <Conceairge isButtonDisable={true} />
        <VendorTestimonial
          title={"Vendor Success Stories"}
          subtitle={"See what our vendors have to say about Spreeh"}
          showBanner={false}
          data={testimonials ? testimonials.vendor : []}
        />
        <Faq />
        <BackToTopButton />
      </Suspense>
    </div>
  );
};
