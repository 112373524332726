import Aos from 'aos';
import React, {useEffect, useState, lazy, Suspense} from 'react';
import { useSelector } from "react-redux";
import Cookies from "js-cookie";

// Lazy load the components
const BannerCarousel = lazy(() => import("../../components/BannerCarousel"));
const TopVendors = lazy(() => import("./Sections/TopVendors"));
const QuoteNumbers = lazy(() => import("./Sections/QuoteNumbers"));
const BannerVideo = lazy(() => import("./Sections/BannerVideo"));
const ClientTestimonials = lazy(() => import("./Sections/ClientTestimonials"));
const Conceairge = lazy(() => import("./Sections/Conceairge"));
const HashtagGeneration = lazy(() => import("./Sections/HashtagGeneration"));
const Packages = lazy(() => import("./Sections/Packages"));
const ImageGrid = lazy(() => import("./Sections/ImageGrid"));
const VendorTestimonial = lazy(() => import("./Sections/VendorTestimonial"));
const EstimateCalculator = lazy(() => import("./Sections/EstimateCalculator"));
const Blogs = lazy(() => import("./Sections/Blogs"));
const Faq = lazy(() => import("./Sections/Faq"));
const GetInTouchModal = lazy(() => import("../../components/GetInTouchModal"));
const BackToTopButton = lazy(() => import("../../components/BackToTopButton"));

const setCookiesFromQueryParams = () => {
  const params = new URLSearchParams(window.location.search);

  const utmSource = params.get("utm_source");
  const utmMedium = params.get("utm_medium");
  const utmCampaign = params.get("utm_campaign");
  const utmContent = params.get("utm_content");
  const utmTerm = params.get("utm_term");
  const gadid = params.get("gadid");

  // Set cookies with a 7-day expiration time
  if (utmSource) Cookies.set("utm_source", utmSource, { expires: 7 });
  if (utmMedium) Cookies.set("utm_medium", utmMedium, { expires: 7 });
  if (utmCampaign) Cookies.set("utm_campaign", utmCampaign, { expires: 7 });
  if (utmContent) Cookies.set("utm_content", utmContent, { expires: 7 });
  if (utmTerm) Cookies.set("utm_term", utmTerm, { expires: 7 });
  if (gadid) Cookies.set("gadid", gadid, { expires: 7 });
};

const Home = () => {
  // const [testimonials, setTestimonials] = useState([]);
  const {
    bannerData,
    categoryData,
    occasionData,
    videoData,
    hashtagImg,
    calculatorImg,
    imgGrid,
  } = useSelector((state) => state.home);

  useEffect(() => {
    setCookiesFromQueryParams();
  }, []);

  const { items: packages } = useSelector((state) => state.package);
  const { item: testimonials } = useSelector((state) => state.testimonial);

  const [showModal, setShowModal] = useState(false);

  return (
    <div className="w-full min-h-screen bg-white">
      <Suspense>
        <BannerCarousel
          setShowModal={setShowModal}
          showModal={showModal}
          data={bannerData}
        />
        <TopVendors data={categoryData} />
        <QuoteNumbers showInfoQuotes={true} />
        <BannerVideo data={videoData} />
        <ClientTestimonials data={testimonials ? testimonials.customer : []} />
        <Conceairge setShowModal={setShowModal} isButtonDisable={false} />
        <HashtagGeneration data={hashtagImg} />
        <div className="hidden md:block">
          <Packages
            btnText={"Explore Packages"}
            occasions={occasionData}
            packages={packages}
          />
        </div>
        <div className="block md:hidden">
          <Packages
            btnText={"Explore More"}
            occasions={occasionData}
            packages={packages}
          />
        </div>
        <ImageGrid images={imgGrid.slice(2, 8)} />
        <VendorTestimonial
          title={"Vendor Testimonials"}
          subtitle={
            "Real Experiences. Genuine Reviews. Don't just take our word for it, believe them."
          }
          showBanner={true}
          data={testimonials ? testimonials.vendor : []}
          showMobile={true}
        />
        <EstimateCalculator data={calculatorImg} />
        <Blogs />
        <Faq />
        <GetInTouchModal showModal={showModal} setShowModal={setShowModal} />
        <BackToTopButton />
      </Suspense>
    </div>
  );
};

export default Home;
