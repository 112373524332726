// src/store/homeSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchBlogsData = createAsyncThunk(
  "home/fetchBlogs",
  async () => {
    const res = await fetch("https://dev.spreeh.com/apps/latestposts");
    const data = await res.json();
    return data;
  }
);

export const blogsSlice = createSlice({
  name: "blogs",
  initialState: {
    items: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBlogsData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBlogsData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.items = action.payload.data;
      })
      .addCase(fetchBlogsData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default blogsSlice.reducer;
