import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Router from './routes';
import {useDispatch} from 'react-redux';
import {fetchHomeData} from './store/homeSlice';
import Footer from './components/Footer';
import {useEffect} from 'react';
import {fetchPackagesData} from './store/packagesSlice';
import {fetchTestimonialData} from './store/testimonialSlice';
import {fetchBlogsData} from './store/blogsSlice';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchHomeData());
    dispatch(fetchPackagesData());
    dispatch(fetchTestimonialData());
    dispatch(fetchBlogsData());
  }, [dispatch]);

  return (
    <h1 className="w-full min-h-screen bg-white">
      <Header />
      <Router />
      <Footer />
    </h1>
  );
}

export default App;
