import PrimaryButton from "../../components/PrimaryButton";
import { useLocation } from "react-router-dom";

const CareerJob = () => {
  const location = useLocation();
  const { job } = location.state || {};

  return (
    <div className="font-['Manrope'] max-w-2xl md:max-w-screen-2xl md:p-10 mx-auto p-6">
      <h2 className="text-base md:text-2xl font-extrabold mb-2 pt-10">
        {job.title}
      </h2>
      <div className="text-xs md:text-base font-bold text-gray-600 mb-4 pt-4">
        {job.loc && (
          <p className="flex pt-2 items-baseline">
            <strong className="mr-2">Location</strong>
            <span className="font-normal">{job.loc}</span>
          </p>
        )}
        {job.type && (
          <p className="flex pt-2 items-baseline">
            <strong className="mr-2">Job Type:</strong>
            <span className="font-normal">{job.type}</span>
          </p>
        )}
        {job.department && (
          <p className="flex pt-2 items-baseline">
            <strong className="mr-2">Department:</strong>
            <span className="font-normal">{job.department}</span>
          </p>
        )}
        {job.experience && (
          <p className="flex pt-2 items-baseline">
            <strong className="mr-2">Experience:</strong>
            <span className="font-normal">{job.experience}</span>
          </p>
        )}
      </div>
      {job.description && (
        <>
          <h3 className="text-sm md:text-base pt-14 font-bold mb-2">
            Role Description
          </h3>
          <p className="text-sm md:text-base font-normal text-gray-800 mb-4">
            {job.description}
          </p>
        </>
      )}

      {job.qualifications && (
        <>
          <h3 className="text-sm md:text-base font-bold pt-14 mb-2">
            Qualifications
          </h3>
          <ul className="text-sm md:text-base font-normal list-disc list-inside text-gray-800 mb-4">
            {job.qualifications.map((qual, index) => (
              <li key={index}>{qual}</li>
            ))}
          </ul>
        </>
      )}

      {job.responsibilities && (
        <>
          <h3 className="text-sm md:text-base font-bold pt-14 mb-2">
            Responsibilities
          </h3>
          <ul className="text-sm md:text-base font-normal list-disc list-inside text-gray-800 mb-4">
            {job.responsibilities.map((qual, index) => (
              <li key={index}>{qual}</li>
            ))}
          </ul>
        </>
      )}

      <p className="text-gray-800 mb-4 pt-14 font-bold text-sm md:text-base ">
        Email us your CV at{" "}
        <a href={`mailto:${job.email}`} className="text-blue-500">
          {job.email}
        </a>
      </p>
      <div className="flex justify-center pt-14 ">
        <PrimaryButton
          text={"Apply Now"}
          styles={{
            color: "#fff",
            fonSize: "14px",
            fontWeight: "600",
            width: "200px",
            height: "50px",
          }}
          handleClick={() => (window.location.href = `mailto:${job.email}`)}
        />
      </div>
    </div>
  );
};

export default CareerJob;
