import React from 'react'
import PrivacyPolicyDetails from '../../components/PrivacyPolicyDetails'

export const PrivacyPolicy = () => {
  return (
    <div className="w-full min-h-screen bg-white px-9 mt-20">
       <PrivacyPolicyDetails />
    </div>
  )
}
