import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import menu_x from "../assets/images/menu_x.png";
import { IoIosArrowBack } from "react-icons/io";
import Cookies from "js-cookie";

const CRYPTO_JS = require("crypto-js");
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

function computeHmacSha256(data, key) {
  const keys = Object.keys(data).sort();
  const sortedObject = {};
  keys.forEach((key) => {
    sortedObject[key] = data[key];
  });
  let dataString = "";
  for (let key in sortedObject) {
    const value = sortedObject[key];
    if (Array.isArray(value) || typeof value === "object") {
      dataString += CRYPTO_JS.SHA256(JSON.stringify(value)).toString(
        CRYPTO_JS.enc.Hex
      );
    } else {
      dataString += value;
    }
  }
  const hash = CRYPTO_JS.HmacSHA256(dataString, key);
  return CRYPTO_JS.enc.Hex.stringify(hash);
}

const VendorSignUpForm = ({ showModal, setShowModal }) => {
  const { categoryData } = useSelector((state) => state.home);
  const [checkedCategories, setCheckedCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [formData, setFormData] = useState([]);
  const [otp, setOtp] = useState("");
  const [utmSource, setUtmSource] = useState("Site Default");
  const [utmMedium, setUtmMedium] = useState("Site Default");
  const [utmCampaign, setUtmCampaign] = useState("Site Default");
  const [utmContent, setUtmContent] = useState("Site Default");
  const [utmTerm, setUtmTerm] = useState("Site Default");
  const [gadid, setGadid] = useState("Site Default");
  const [timer, setTimer] = useState(60);
  const [isDisabled, setIsDisabled] = useState(true);

  const handleCategoryChange = (categoryID) => {
    setCheckedCategories((prev) =>
      prev.includes(categoryID)
        ? prev.filter((id) => id !== categoryID)
        : [...prev, categoryID]
    );
  };

  const handleSubcategoryChange = (event, subcategoryID) => {
    const { checked } = event.target;
    const subcategory = formik.values.subcategory || [];

    if (checked) {
      formik.setFieldValue("subcategory", [...subcategory, subcategoryID]);
    } else {
      formik.setFieldValue(
        "subcategory",
        subcategory.filter((id) => id !== subcategoryID)
      );
    }
  };

  const validateOtpRequest = async (formData) => {
    try {
      const requestBody = {
        mobile: formData.phoneNumber,
        utype: "customer",
        otp: otp,
        country_code:
          formData.countryCode == null ? "+91" : formData.countryCode,
      };

      const hashValue = computeHmacSha256(requestBody, SECRET_KEY);

      const response = await fetch("https://dev.spreeh.com/apps/verifyotp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Hash": hashValue,
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      if (data.status === "ok") {
        const hashValue = computeHmacSha256(formData, SECRET_KEY);
        try {
          const response = await fetch("https://dev.spreeh.com/apps/vsignup", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-Hash": hashValue,
            },
            body: JSON.stringify(formData),
          });
          const data = await response.json();
          if (data.status === "ok") {
            toast.success("Vendor registered successfully!", {
              position: "top-right",
              autoClose: 3000,
            });
          } else {
            toast.error(data.errors, {
              position: "top-right",
              autoClose: 3000,
            });
          }
        } catch (error) {
          console.error("Error:", error);
          toast.error("Failed to register vendor. Please try again.", {
            position: "top-right",
            autoClose: 3000,
          });
        }
      } else {
        toast.error("Failed to verify OTP. Please try again.", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error("Error verifieng OTP:", error);
    }
  };

  const sendOtpRequest = async (formData, resend) => {
    try {
      const requestBody = {
        mobile: formData.phoneNumber,
        utype: "customer",
        country_code:
          formData.countryCode == null ? "+91" : formData.countryCode,
      };

      const hashValue = computeHmacSha256(requestBody, SECRET_KEY);

      const response = await fetch("https://dev.spreeh.com/apps/sendotp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Hash": hashValue,
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (data.status === "ok") {
        if (!resend) {
          let currPage = currentPage;
          currPage++;
          setCurrentPage(currPage);
        }
        toast.success("OTP Sent successfully!", {
          position: "top-right",
          autoClose: 3000,
        });
      } else {
        toast.error("Failed to generate OTP. Please try again.", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      businessName: "",
      businessWebsite: "",
      baseLocation: "",
      youtubeUrl: "",
      instagramUrl: "",
      companyDeck: "",
      categories: [],
      subcategory: [],
      countryCode: "+91",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      phoneNumber: Yup.string().required("Phone Number is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      businessName: Yup.string().required("Business Name is required"),
      baseLocation: Yup.string().required("Base Location is required"),
    }),
    onSubmit: async (values) => {
      const {
        baseLocation,
        businessName,
        businessWebsite,
        categories,
        subcategory,
        companyDeck,
        email,
        firstName,
        instagramUrl,
        lastName,
        phoneNumber,
        youtubeUrl,
        countryCode,
      } = values;

      const requestData = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        gender: "",
        phoneNumber: phoneNumber,
        country_code: countryCode,
        businessName: businessName,
        businessWebsite: businessWebsite,
        businessCity: "",
        businessAddress: baseLocation,
        youtubeLink: youtubeUrl,
        instaprofileurl: instagramUrl,
        category: categories,
        subcategory: subcategory,
        businessprofilemime: "",
        businessprofile: companyDeck,
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_campaign: utmCampaign,
        utm_content: utmContent,
        utm_term: utmTerm,
        gadid: gadid,
      };
      setFormData(requestData);

      sendOtpRequest(requestData, false);
    },
  });

  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowModal(false);
    }
  };

  const handleBackClick = () => {
    let currPage = currentPage;
    currPage--;
    setCurrentPage(currPage);
  };

  useEffect(() => {
    if (showModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showModal]);

  useEffect(() => {
    if (currentPage > 0) {
      setTimer(60);
    }
  }, [currentPage]);

  useEffect(() => {
    if (timer > 0) {
      const countdown = setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
      return () => clearTimeout(countdown);
    } else {
      setIsDisabled(false); // Enable the button when the timer ends
    }
  }, [timer]);

  const handleResendClick = () => {
    if (!isDisabled) {
      sendOtpRequest(formData, true);
      setIsDisabled(true); // Disable the button again
      // setTimer(15); // Reset the timer to 15 seconds
    }
  };

  useEffect(() => {
    // Retrieve cookies with defaults if undefined
    setUtmSource(Cookies.get("utm_source") || "Site Default");
    setUtmMedium(Cookies.get("utm_medium") || "Site Default");
    setUtmCampaign(Cookies.get("utm_campaign") || "Site Default");
    setUtmContent(Cookies.get("utm_content") || "Site Default");
    setUtmTerm(Cookies.get("utm_term") || "Site Default");
    setGadid(Cookies.get("gadid") || "Site Default");
  }, []);

  if (!showModal) return null;

  return (
    <div className="p-2 fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50 pt-10">
      <div
        className={` w-full md:mb-24 ${
          currentPage === 0 ? "max-w-4xl" : "max-w-xl"
        } max-h-[90vh] overflow-y-auto`}
      >
        <div
          className={`p-6 bg-white rounded-lg shadow-md ${
            currentPage === 0 ? "" : "min-h-[60vh]"
          }`}
        >
          {currentPage > 0 ? (
            <div className="flex justify-between">
              <div className="flex justify-start align-center text-base pb-10 md:pt-6 md:pl-4">
                <IoIosArrowBack
                  className="text-medium-pink cursor-pointer"
                  onClick={handleBackClick}
                />
                <div className="mt-[-5px]" onClick={handleBackClick}>
                  {"Back"}
                </div>
              </div>
              <div
                className="flex justify-end align-center text-base md:pt-6 md:pr-6 md:pr-6"
                onClick={() => setShowModal(false)}
              >
                <img
                  alt="img"
                  className="w-[10px] h-[10px] object-cover"
                  src={menu_x}
                />
              </div>
            </div>
          ) : (
            <div
              className="flex justify-end align-center text-base pb-10 md:pr-6"
              onClick={() => setShowModal(false)}
            >
              <img
                alt="img"
                className="w-[10px] h-[10px] object-cover"
                src={menu_x}
              />
            </div>
          )}
          {currentPage === 0 && (
            <>
              <h2
                className="text-2xl font-bold  text-center mb-6 text-medium-pink"
                style={{
                  fontFamily: "Tiro Devanagari Sanskrit, serif",
                  fontWeight: 400,
                  fontStyle: "italic",
                }}
              >
                Vendor Sign Up
              </h2>
              <form onSubmit={formik.handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* First Name */}
                  <div className="font-['Manrope']">
                    <label
                      htmlFor="firstName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      First Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      id="firstName"
                      name="firstName"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName}
                      className="mt-1 block w-full px-4 py-2 border rounded-md focus:border-pink-500 focus:ring focus:ring-opacity-50"
                    />
                    {formik.touched.firstName && formik.errors.firstName ? (
                      <div className="text-red-600 text-sm mt-1">
                        {formik.errors.firstName}
                      </div>
                    ) : null}
                  </div>

                  {/* Last Name */}
                  <div className="font-['Manrope']">
                    <label
                      htmlFor="lastName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Last Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      id="lastName"
                      name="lastName"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName}
                      className="mt-1 block w-full px-4 py-2 border rounded-md focus:border-pink-500 focus:ring focus:ring-opacity-50"
                    />
                    {formik.touched.lastName && formik.errors.lastName ? (
                      <div className="text-red-600 text-sm mt-1">
                        {formik.errors.lastName}
                      </div>
                    ) : null}
                  </div>

                  {/* Phone Number */}
                  <div className="font-['Manrope']">
                    <label
                      htmlFor="phoneNumber"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Phone Number <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="flex justify-center align-center mt-1">
                      <select
                        id="countryCode"
                        name="countryCode"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.countryCode}
                        className="px-3 py-2 h-10 mt-1 border rounded-md border-gray-300 bg-gray-100 focus:border-pink-500 text-xs"
                      >
                        <option value="+91">+91 (IN)</option>
                        <option value="+1">+1 (US)</option>
                        <option value="+44">+44 (UK)</option>
                        <option value="+61">+61 (AU)</option>
                        {/* Add more country codes as needed */}
                      </select>
                      <input
                        id="phoneNumber"
                        name="phoneNumber"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phoneNumber}
                        className="mt-1 block w-full px-4 py-2 border rounded-md focus:border-pink-500 focus:ring focus:ring-opacity-50"
                      />
                    </div>
                    {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                      <div className="text-red-600 text-sm mt-1">
                        {formik.errors.phoneNumber}
                      </div>
                    ) : null}
                  </div>

                  {/* Email */}
                  <div className="font-['Manrope']">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email ID <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      className="mt-1 block w-full px-4 py-2 border rounded-md focus:border-pink-500 focus:ring focus:ring-opacity-50"
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="text-red-600 text-sm mt-1">
                        {formik.errors.email}
                      </div>
                    ) : null}
                  </div>
                </div>

                {/* Category Selection */}
                <div className="font-['Manrope']">
                  <h3 className="block text-sm font-medium text-gray-700">
                    Category
                  </h3>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-2">
                    {categoryData.map((category) => (
                      <div key={category.categoryID} className="flex flex-col">
                        <label className="flex items-center">
                          <input
                            type="checkbox"
                            name="categories"
                            value={category.categoryID}
                            onChange={(event) => {
                              formik.handleChange(event);
                              handleCategoryChange(category.categoryID);
                            }}
                            className="mr-2"
                          />
                          {category.name}
                        </label>

                        {/* Render subcategory if the category is checked */}
                        {checkedCategories.includes(category.categoryID) &&
                          category.subcategory &&
                          category.subcategory.map((sub) => (
                            <label
                              key={sub.categoryID}
                              className="flex items-center ml-4"
                            >
                              <input
                                type="checkbox"
                                name="subcategory"
                                value={sub.categoryID}
                                onChange={(event) =>
                                  handleSubcategoryChange(event, sub.categoryID)
                                }
                                className="mr-2"
                              />
                              {sub.name}
                            </label>
                          ))}
                      </div>
                    ))}
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Business Name */}
                  <div className="font-['Manrope']">
                    <label
                      htmlFor="businessName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Business Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      id="businessName"
                      name="businessName"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.businessName}
                      className="mt-1 block w-full px-4 py-2 border rounded-md focus:border-pink-500 focus:ring focus:ring-opacity-50"
                    />
                    {formik.touched.businessName &&
                    formik.errors.businessName ? (
                      <div className="text-red-600 text-sm mt-1">
                        {formik.errors.businessName}
                      </div>
                    ) : null}
                  </div>

                  {/* Business Website */}
                  <div className="font-['Manrope']">
                    <label
                      htmlFor="businessWebsite"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Business Website
                    </label>
                    <input
                      id="businessWebsite"
                      name="businessWebsite"
                      type="url"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.businessWebsite}
                      className="mt-1 block w-full px-4 py-2 border rounded-md focus:border-pink-500 focus:ring focus:ring-opacity-50"
                    />
                  </div>

                  {/* Base Location */}
                  <div className="font-['Manrope']">
                    <label
                      htmlFor="baseLocation"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Base Location <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      id="baseLocation"
                      name="baseLocation"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.baseLocation}
                      className="mt-1 block w-full px-4 py-2 border rounded-md focus:border-pink-500 focus:ring focus:ring-opacity-50"
                    />
                    {formik.touched.baseLocation &&
                    formik.errors.baseLocation ? (
                      <div className="text-red-600 text-sm mt-1">
                        {formik.errors.baseLocation}
                      </div>
                    ) : null}
                  </div>

                  {/* YouTube URL */}
                  <div className="font-['Manrope']">
                    <label
                      htmlFor="youtubeUrl"
                      className="block text-sm font-medium text-gray-700"
                    >
                      YouTube URL
                    </label>
                    <input
                      id="youtubeUrl"
                      name="youtubeUrl"
                      type="url"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.youtubeUrl}
                      className="mt-1 block w-full px-4 py-2 border rounded-md focus:border-pink-500 focus:ring focus:ring-opacity-50"
                    />
                  </div>

                  {/* Instagram Profile URL */}
                  <div className="font-['Manrope']">
                    <label
                      htmlFor="instagramUrl"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Instagram Profile URL
                    </label>
                    <input
                      id="instagramUrl"
                      name="instagramUrl"
                      type="url"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.instagramUrl}
                      className="mt-1 block w-full px-4 py-2 border rounded-md focus:border-pink-500 focus:ring focus:ring-opacity-50"
                    />
                  </div>

                  {/* Company Deck/Profile */}
                  <div className="font-['Manrope']">
                    <label
                      htmlFor="companyDeck"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Company Deck/Profile
                    </label>
                    <input
                      id="companyDeck"
                      name="companyDeck"
                      type="file"
                      accept="image/*,application/pdf" // accept only images and PDFs
                      onChange={async (event) => {
                        const file = event.currentTarget.files[0];

                        // Validate file size (5MB = 5 * 1024 * 1024 bytes)
                        if (file && file.size <= 5 * 1024 * 1024) {
                          const reader = new FileReader();
                          reader.onloadend = () => {
                            const base64String = reader.result.split(",")[1]; // Get base64 without the "data:" prefix
                            formik.setFieldValue("companyDeck", base64String);
                          };
                          reader.readAsDataURL(file); // Read the file as a Data URL (base64 string)
                        } else {
                          alert("File size should be 5 MB or less");
                        }
                      }}
                      className="mt-1 block w-full px-4 py-2 border rounded-md focus:border-pink-500 focus:ring focus:ring-opacity-50"
                    />
                  </div>
                </div>

                {/* Submit Button */}
                <div className="text-center">
                  <button
                    type="submit"
                    className="w-full md:w-auto inline-block bg-medium-pink text-white px-6 py-2 rounded-md focus:outline-none"
                  >
                    Sign Up
                  </button>
                </div>
              </form>
            </>
          )}
          {currentPage === 1 && (
            <>
              <h2
                className="text-2xl font-bold  text-center mb-6 text-medium-pink"
                style={{
                  fontFamily: "Tiro Devanagari Sanskrit, serif",
                  fontWeight: 400,
                  fontStyle: "italic",
                }}
              >
                Mobile OTP Verification
              </h2>
              <div className="flex flex-col justify-between min-h-72">
                <div className="p-4 mb-4">
                  <label className="block text-black font-['Manrope'] font-semibold text-xs">
                    OTP Sent on {formData.countryCode} {formData.phoneNumber}
                  </label>
                  <div className="grid grid-cols-12 gap-4">
                    <div className="col-span-12">
                      <input
                        id="validateOTP"
                        name="validateOTP"
                        type="text"
                        maxLength={6}
                        onChange={(e) => {
                          setOtp(e.target.value);
                        }}
                        className={`w-full px-3 py-2 mt-1 border rounded focus:border-pink-500 border-gray-300`}
                        placeholder="_ _ _ _ _ _"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-center items-center text-center mt-16">
                  <div
                    onClick={handleResendClick}
                    className={`font-semibold text-sm mb-6 ${
                      isDisabled
                        ? "text-gray-500"
                        : "text-blue-500 cursor-pointer"
                    }`}
                  >
                    {isDisabled
                      ? timer === 0
                        ? "Resend OTP"
                        : `Resend OTP in ${timer}s`
                      : "Resend OTP"}
                  </div>
                  <button
                    className="w-48 h-12 inline-block bg-medium-pink text-white px-6 py-2 rounded-md focus:outline-none"
                    onClick={() => {
                      validateOtpRequest(formData);
                    }}
                  >
                    Verify
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default VendorSignUpForm;
