import React, {useEffect, useState} from 'react';
import ImageGrid from '../Home/Sections/ImageGrid';
import Faq from '../Home/Sections/Faq';
import VendorTestimonial from '../Home/Sections/VendorTestimonial';
import Aos from 'aos';
import 'aos/dist/aos.css';
import PrimaryButton from '../../components/PrimaryButton';
import BecomePartnerQuote from './Section/BecomePartnerQuote';
import VendorSignUpForm from '../../components/VendorSignup';
import {useSelector} from 'react-redux';
import hrLine from '../../assets/images/hrLine.png';

const BecomePartner = () => {
  // const [testimonials, setTestimonials] = useState([]);
  const {imgGrid} = useSelector(state => state.home);

  const [showModal, setShowModal] = useState(false);

  const {item: testimonials} = useSelector(state => state.testimonial);

  useEffect(() => {
    Aos.init({duration: 500});
  }, []);

  return (
    <div className="w-full min-h-screen bg-white">
      <div className="mt-20 mb-1 flex justify-center align-center">
        <div className="flex flex-col w-full justify-center items-center h-full">
          <div
            className="block md:hidden flex flex-col items-center md:mx-0"
            data-aos="fade-up"
          >
            <h2
              className="text-base text-center md:text-xl font-normal mx-2 text-medium-pink"
              style={{
                fontFamily: 'Tiro Devanagari Sanskrit, serif',
                fontWeight: 400,
                fontStyle: 'italic',
              }}
            >
              {'Become a'}
            </h2>
            <h2
              className="text-5xl text-center md:text-5xl font-normal mx-2 text-medium-pink"
              style={{
                fontFamily: 'Tiro Devanagari Sanskrit, serif',
                fontWeight: 400,
                fontStyle: 'italic',
              }}
            >
              {'Partner'}
            </h2>
          </div>
          <div
            className="hidden md:block md:flex md:flex-row md:items-center md:mx-0"
            data-aos="fade-up"
          >
            <h2
              className="text-center text-6xl font-normal mx-2 text-medium-pink"
              style={{
                fontFamily: 'Tiro Devanagari Sanskrit, serif',
                fontWeight: 400,
                fontStyle: 'italic',
              }}
            >
              {'Become a Partner'}
            </h2>
          </div>
          <img
            alt="img"
            className="w-[130px] md:w-[190px] h-[20px] md:h-[28px] object-cover mt-10"
            src={hrLine}
            data-aos="fade-up"
          />
          <div
            className="max-w-[330px] md:max-w-3xl flex justify-center align-center text-center text-base font-normal mt-14"
            data-aos="fade-up"
          >
            Calling all Wedding and Events Vendors! Elevate your business by
            partnering with Spreeh. Showcase your services to a wider audience
            and create unforgettable moments together. Partner with Spreeh now!
          </div>
          <div className="mt-14 md:mt-20" data-aos="fade-up">
            <PrimaryButton
              text="Partner With Us"
              styles={{
                color: '#fff',
                fonSize: '14px',
                fontWeight: '600',
                width: '200px',
                height: '50px',
              }}
              handleClick={() => {
                setShowModal(true);
              }}
            />
          </div>

          <BecomePartnerQuote showInfoQuotes={false} />
          <div className="mt-10 md:mt-20">
            <ImageGrid
              images={imgGrid && imgGrid.length > 0 ? imgGrid?.slice(2, 8) : []}
            />
          </div>
          <VendorTestimonial
            title="Vendor Testimonials"
            subtitle="Real Experiences. Genuine Reviews. Don't just take our word for it, believe them."
            // showBanner={true}
            // showButton={true}
            // showMobile={true}
            data={testimonials ? testimonials.vendor : []}
          />

          <Faq />
        </div>
      </div>
      <VendorSignUpForm showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default BecomePartner;
