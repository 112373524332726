// src/store/store.js
import {configureStore} from '@reduxjs/toolkit';
import homeReducer from './homeSlice';
import packageReducer from './packagesSlice';
import testimonialReducer from './testimonialSlice';
import blogReducer from './blogsSlice';

export const store = configureStore({
  reducer: {
    home: homeReducer,
    package: packageReducer,
    testimonial: testimonialReducer,
    blogs: blogReducer,
  },
});
