/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

const ImageGrid = ({ images }) => {
  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);

  return (
    <>
      <div className="hidden md:grid w-full gap-2 grid-cols-12 h-128 px-1 my-8 pt-10">
        <div className="col-span-5">
          <img
            src={images[0]}
            alt={"images[4]"}
            className="w-full h-[491px] object-cover rounded-lg"
          />
        </div>
        <div className="col-span-2">
          <img
            src={images[1]}
            alt={"images[5].name"}
            className="w-full h-[491px] object-cover rounded-lg"
          />
        </div>
        <div className="col-span-5">
          <img
            src={images[2]}
            alt={"images[6].name"}
            className="w-full h-[491px] object-cover rounded-lg"
          />
        </div>
        <div className="col-span-3 row-start-2">
          <img
            src={images[3]}
            alt={"images[7].name"}
            className="w-full h-[517px] object-cover rounded-lg"
          />
        </div>
        <div className="col-span-6 row-start-2">
          <img
            src={images[4]}
            alt={"images[8].name"}
            className="w-full h-[517px] object-cover rounded-lg"
          />
        </div>
        <div className="col-span-3 row-start-2">
          <img
            src={images[5]}
            alt={"images[9].name"}
            className="w-full h-[517px] object-cover rounded-lg"
          />
        </div>
      </div>

      <div className="block md:hidden w-full grid gap-2 grid-rows-6 grid-flow-col px-1 my-8">
        <div className="row-span-3 col-span-1" data-aos="fade-right">
          <img
            src={images[0]}
            alt={"images[4].name"}
            className="w-full h-full object-cover rounded-lg"
          />
        </div>

        <div className="row-span-2 col-span-1" data-aos="fade-right">
          <img
            src={images[2]}
            alt={"images[5].name"}
            className="w-full h-full object-cover rounded-lg"
          />
        </div>

        <div className="row-span-1 col-span-1" data-aos="fade-right">
          <img
            src={images[4]}
            alt={"images[6].name"}
            className="w-full h-full object-cover rounded-lg"
          />
        </div>
        <div className="row-span-2 col-span-1" data-aos="fade-right">
          <img
            src={images[1]}
            alt={"images[7].name"}
            className="w-full h-full object-cover rounded-lg"
          />
        </div>
        <div className="row-span-2 col-span-1" data-aos="fade-right">
          <img
            src={images[3]}
            alt={"images[8].name"}
            className="w-full h-full object-cover rounded-lg"
          />
        </div>
        <div className="row-span-2 col-span-1" data-aos="fade-right">
          <img
            src={images[5]}
            alt={"images[9].name"}
            className="w-full h-full object-cover rounded-lg"
          />
        </div>
      </div>
    </>
  );
};

export default ImageGrid;
