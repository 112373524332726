import React, { useEffect, useState, useRef } from "react";
import InfoQuoteNumbers from "../Home/Sections/InfoQuoteNumbers";
import SectionLabel from "../../components/SectionLabel";
import QuoteNumbers from "../Home/Sections/QuoteNumbers";
import ImageGrid from "../Home/Sections/ImageGrid";
import Faq from "../Home/Sections/Faq";
import Conceairge from "../Home/Sections/Conceairge";
import AboutImageGrid from "./Sections/AboutImageGrid";
import Aos from "aos";
import "aos/dist/aos.css";
import GetInTouchModal from "../../components/GetInTouchModal";
import { useSelector } from "react-redux";
import hrLine from "../../assets/images/hrLine.png";

const AboutUs = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const contentRef = useRef(null);
  const { aboutUs: images } = useSelector((state) => state.home);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (contentRef.current) {
        const contentHeight = contentRef.current.scrollHeight;
        const containerHeight = contentRef.current.clientHeight;
        const hasOverflowingContent = contentHeight > 264; // 16.5rem in pixels
        setIsOverflowing(hasOverflowingContent);
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [isExpanded]); // Add isExpanded to dependencies to re-check overflow on toggle

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    // getData();
    Aos.init({ duration: 500 });
  }, []);

  return (
    <div className="w-full min-h-screen bg-white">
      <div className="mt-20 mb-1 flex justify-center align-center">
        <div className="flex flex-col w-full justify-center items-center h-full">
          <div
            className="block md:hidden flex flex-col items-center md:mx-0"
            data-aos="fade-up"
          >
            <h2
              className="text-base text-center md:text-xl font-normal mx-2 text-medium-pink"
              style={{
                fontFamily: "Tiro Devanagari Sanskrit, serif",
                fontWeight: 400,
                fontStyle: "italic",
              }}
            >
              {"About"}
            </h2>
            <h2
              className="text-5xl text-center md:text-5xl font-normal mx-2 text-medium-pink"
              style={{
                fontFamily: "Tiro Devanagari Sanskrit, serif",
                fontWeight: 400,
                fontStyle: "italic",
              }}
            >
              {"Spreeh"}
            </h2>
          </div>
          <div
            className="hidden md:block md:flex md:flex-row md:items-center md:mx-0"
            data-aos="fade-up"
          >
            <h2
              className="text-center text-6xl font-normal mx-2 text-medium-pink"
              style={{
                fontFamily: "Tiro Devanagari Sanskrit, serif",
                fontWeight: 400,
                fontStyle: "italic",
              }}
            >
              {"About Spreeh"}
            </h2>
          </div>
          <img
            alt="img"
            className="w-[130px] md:w-[190px] h-[20px] md:h-[28px] object-cover mt-10"
            src={hrLine}
            data-aos="fade-up"
          />
          <div
            className="max-w-[330px] md:max-w-3xl flex justify-center align-center text-center text-base font-normal mt-14"
            data-aos="fade-up"
          >
            Welcome to Spreeh, where we're leading a revolution in event and
            wedding planning. Our seamless platform offers unmatched
            convenience, efficiency, and personalised experiences curated by
            experts with extensive wedding planning experience. Customised
            precisely to your budget and desires. By eliminating the middleman,
            we ensure 100% pricing transparency.
          </div>
          <div className="mt-10 md:mt-20" data-aos="fade-up">
            <InfoQuoteNumbers
              stylesNum={{ color: "#ec7d8b" }}
              stylesFont={{ color: "#000000" }}
            />
          </div>
          <div className="mt-10 md:mt-20" data-aos="fade-right">
            <AboutImageGrid data={images && images.length > 0 ? images : []} />
          </div>
          <div className="mt-10 md:mt-20" data-aos="fade-up">
            <div className="w-full">
              <SectionLabel
                text={"Our Values"}
                styles={{
                  marginLeft: "2rem",
                  marginRight: "2rem",
                }}
              />
            </div>
            <div className="max-w-[330px] md:max-w-3xl flex flex-col justify-center items-center text-center text-base font-normal mt-4">
              <div
                ref={contentRef}
                className={`overflow-hidden ${
                  isExpanded ? "h-auto" : "h-[16.5rem]"
                } transition-all duration-300`}
              >
                Welcome to Spreeh, where we believe every celebration should be
                seamless. Our mission is to revolutionise event planning with a
                one-stop platform that simplifies every step. From finding the
                right vendors to managing budgets, we streamline the process,
                making it easier than ever to plan unforgettable gatherings. Our
                user-friendly platform presents options curated to your budget
                and requirements, ensuring top quality. We specialise in
                personalised experiences, making each celebration unique to your
                preferences and needs, whether it's a birthday party or a grand
                wedding. Join us as we redefine the art of celebration. Creating
                memories, one event at a time.
              </div>
              {isOverflowing && (
                <button
                  className="mt-2 text-black-500 font-semibold hover:underline"
                  onClick={handleToggle}
                >
                  {isExpanded ? "Read Less-" : "Read More+"}
                </button>
              )}
            </div>
          </div>
          <QuoteNumbers showInfoQuotes={false} />
          <div className="mt-10 md:mt-20">
            <ImageGrid
              images={images && images.length > 0 ? images?.slice(4, 10) : []}
            />
          </div>
          <Conceairge setShowModal={setShowModal} showModal={showModal} />
          <Faq />
        </div>
      </div>
      <GetInTouchModal showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default AboutUs;
