import React, { useState } from "react";
import logo from "../assets/images/logo.png";
import { Outlet, NavLink } from "react-router-dom";
import menu_x from "../assets/images/menu_x.png";

const Header = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const activeStyleMobile = "text-medium-pink px-4 py-2 rounded";
  const linkStyleMobile =
    "font-semibold font-['Manrope'] cursor-pointer py-2 px-4 hover:bg-slate-100 rounded";

  const linkStyleDesktop =
    "font-semibold font-['Manrope'] cursor-pointer text-sm";

  return (
    <div className="w-full min-h-12 flex flex-row justify-between items-center container mx-auto py-2 px-9 relative z-21">
      <div>
        <NavLink to="/">
          <img className="w-24 h-9" alt="logo" src={logo} />
        </NavLink>
      </div>
      <div className="lg:hidden" onClick={toggleDrawer}>
        <div className="flex flex-col gap-2 cursor-pointer">
          <div className="w-6 h-0.5 bg-light-pink rounded-full"></div>
          <div className="w-6 h-0.5 bg-light-pink rounded-full"></div>
        </div>
      </div>
      <div className="hidden lg:flex gap-12">
        <NavLink to="/services" className={linkStyleDesktop}>
          Services
        </NavLink>
        <NavLink to="/partner-with-us" className={linkStyleDesktop}>
          Become a Partner
        </NavLink>
        <a href="https://spreeh.com/blog/" className={linkStyleDesktop}>
          Blogs
        </a>
        <NavLink to="/careers" className={linkStyleDesktop}>
          Careers
        </NavLink>
        <NavLink to="/about-us" className={linkStyleDesktop}>
          About
        </NavLink>
      </div>
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 ${
          isDrawerOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        } z-30`}
        onClick={toggleDrawer}
      >
        <div
          className={`fixed top-0 right-0 w-3/4 h-full bg-white p-4 transition-transform duration-300 transform ${
            isDrawerOpen ? "-translate-x-0" : "translate-x-full"
          } z-40`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex flex-col gap-6">
            <div>
              <div
                className="pt-2 flex flex-row justify-end font-semibold pr-7"
                onClick={toggleDrawer}
              >
                <img
                  alt="img"
                  className="w-[12px] h-[12px] object-cover"
                  src={menu_x}
                />
              </div>
            </div>

            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive
                  ? `${activeStyleMobile} ${linkStyleMobile}`
                  : linkStyleMobile
              }
              onClick={toggleDrawer}
            >
              Home
            </NavLink>

            <NavLink
              to="/services"
              className={({ isActive }) =>
                isActive
                  ? `${activeStyleMobile} ${linkStyleMobile}`
                  : linkStyleMobile
              }
              onClick={toggleDrawer}
            >
              Services
            </NavLink>
            <NavLink
              to="/partner-with-us"
              className={({ isActive }) =>
                isActive
                  ? `${activeStyleMobile} ${linkStyleMobile}`
                  : linkStyleMobile
              }
              onClick={toggleDrawer}
            >
              Become a Partner
            </NavLink>
            <a href="https://spreeh.com/blog/" className={linkStyleMobile}>
              Blogs
            </a>
            <NavLink
              to="/careers"
              className={({ isActive }) =>
                isActive
                  ? `${activeStyleMobile} ${linkStyleMobile}`
                  : linkStyleMobile
              }
              onClick={toggleDrawer}
            >
              Careers
            </NavLink>
            <NavLink
              to="/about-us"
              className={({ isActive }) =>
                isActive
                  ? `${activeStyleMobile} ${linkStyleMobile}`
                  : linkStyleMobile
              }
              onClick={toggleDrawer}
            >
              About
            </NavLink>
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default Header;
